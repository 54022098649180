import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/shared/services/app.layout.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Resource } from '../../shared/constants';

@Component({
  selector: 'app-menu-template',
  templateUrl: './menu-template.component.html',
  styleUrls: ['./menu-template.component.scss'],
})
export class MenuTemplateComponent implements OnInit {
  model: any[] = [];

  constructor(public layoutService: LayoutService, public permissionService: PermissionService) {}

  ngOnInit(): void {
    this.model = [
      {
        label: '',
        isPublic: true,
        items: [{ label: 'Inicio', icon: 'pi pi-fw pi-home', routerLink: ['/home'], isPublic: true }],
      },
      {
        label: 'Menú',
        isPublic: true,
        items: [
          {
            label: 'Usuarios',
            icon: 'pi pi-fw pi-user',
            resourceName: 'USUARIOS_ADMIN_MENU',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Administración de usuarios',
                icon: 'pi pi-fw pi-user',
                resourceName: 'USUARIOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/user'],
              },
              {
                label: 'Administración de Roles',
                icon: 'pi pi-fw pi-list',
                resourceName: 'ROLES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/role'],
              },
              {
                label: 'Administración de Permisos del Sistema',
                icon: 'pi pi-fw pi-th-large',
                resourceName: 'PERMISOS_SISTEMA',
                right: 'canView',
                isPublic: false,
                routerLink: ['/system-acl'],
              },
              {
                label: 'Log de Actividad',
                icon: 'pi pi-fw pi-list',
                resourceName: 'LOG_ACTIVIDADES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/log-activity'],
              },
            ],
          },
          {
            label: 'Gestión de Planes',
            icon: 'pi pi-fw pi-briefcase',
            resourceName: 'ADMINISTRACION_PLANES_MENU',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Tipos de Planes',
                icon: 'pi pi-fw pi-tags',
                resourceName: 'TIPO_PLANES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/type-of-plan'],
              },
              {
                label: 'Planes',
                icon: 'pi pi-fw pi-briefcase',
                resourceName: 'PLANES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/plans'],
              },
              {
                label: 'Ámbitos',
                icon: 'pi pi-fw pi-box',
                resourceName: 'AMBITOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/ambit'],
              },
              {
                label: 'Sectores',
                icon: 'pi pi-fw pi-building',
                resourceName: 'SECTORES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/sector'],
              },
              {
                label: 'Categorías',
                icon: 'pi pi-fw pi-book',
                resourceName: 'CATEGORIAS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/category'],
              },
            ],
          },
          {
            label: 'Gestión de Estándares',
            icon: 'pi pi-fw pi-book',
            resourceName: 'ADMINISTRACION_ESTANDARES_MENU',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Administración de Estándares',
                icon: 'pi pi-fw pi-bookmark-fill',
                resourceName: 'ESTANDARES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/standards'],
              },
            ],
          },
          {
            label: 'Gestión Documental',
            icon: 'pi pi-fw pi-folder',
            resourceName: 'ADMINISTRACION_DOCUMENTOS_MENU',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Administración Tipo de Documentos',
                icon: 'pi pi-fw pi-list',
                resourceName: 'TIPO_DOCUMENTOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/documents/types'],
              },
              {
                label: 'Gestión de Documentos',
                icon: 'pi pi-fw pi-file',
                resourceName: 'GESTION_DOCUMENTOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/documents'],
              },
            ],
          },
          {
            label: 'Recursos de Información',
            icon: 'pi pi-fw pi-verified',
            resourceName: 'ADMINISTRACION_RECURSOS_INFORMACION',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Tipos de recursos',
                icon: 'pi pi-fw pi-verified',
                resourceName: 'RECURSOS_INFORMACION',
                right: 'canView',
                isPublic: false,
                routerLink: ['/information-resource-type'],
              },
              {
                label: 'Recursos de Información',
                icon: 'pi pi-fw pi-map-marker',
                resourceName: 'RECURSOS_INFORMACION',
                right: 'canView',
                isPublic: false,
                routerLink: ['/information-resource'],
              },
            ],
          },
          {
            label: 'Recursos Cartográficos',
            icon: 'pi pi-fw pi-map',
            resourceName: 'ADMINISTRACION_RECURSOS_CARTOGRAFICOS',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Interoperabilidad',
                icon: 'pi pi-fw pi-globe',
                resourceName: 'SERVICIOS_MAPAS_WEB',
                right: 'canView',
                isPublic: false,
                routerLink: ['/web-map-service'],
              },
              {
                label: 'Recursos Cartográficos',
                icon: 'pi pi-fw pi-map',
                resourceName: 'RECURSOS_CARTOGRAFICOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/cartographic-resource'],
              },
              {
                label: 'Estilos',
                icon: 'pi pi-fw pi-palette',
                resourceName: 'RECURSOS_CARTOGRAFICOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/sld'],
              },
            ],
          },
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-th-large',
            resourceName: Resource.MENU_DASHBOARDS,
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Tableros Dinámicos',
                icon: 'pi pi-fw pi-th-large',
                resourceName: Resource.DASHBOARDS,
                right: 'canView',
                isPublic: false,
                routerLink: ['/dashboards'],
              },
              // Change behavior once module is added
              {
                label: 'Tableros Interactivos',
                icon: 'pi pi-fw pi-th-large',
                resourceName: Resource.DASHBOARDS,
                right: 'canView',
                isPublic: true,
              },
            ],
          },
          {
            label: 'Geovisor',
            icon: 'pi pi-fw pi-map',
            resourceName: 'MENU_GEOVISOR',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Geovisor',
                icon: 'pi pi-fw pi-map',
                resourceName: 'INTERNAL_GEOVISOR',
                right: 'canView',
                isPublic: false,
                routerLink: ['/internal/geovisor'],
              },
              {
                label: 'Geovisor externo',
                icon: 'pi pi-fw pi-map',
                resourceName: 'GEOVISOR',
                right: 'canView',
                isPublic: false,
                routerLink: ['/geovisor'],
              },
            ],
          },
        ],
      },

      {
        label: 'Transparencia',
        isPublic: false,
        resourceName: [
          Resource.MENU_BIG_DATA,
          Resource.MENU_BIG_DATA_TRACKING,
          Resource.MENU_BIG_DATA_DASHBOARDS,
          Resource.MENU_DASHBOARDS,
          Resource.BIG_DATA_REPORTS,
        ],
        right: 'canView',
        items: [
          {
            label: 'NDC',
            icon: 'pi pi-fw pi-server',
            resourceName: Resource.MENU_BIG_DATA,
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Sector NDC',
                icon: 'pi pi-fw pi-chart-pie',
                resourceName: Resource.BIG_DATA_SECTORS_NDC,
                right: 'canView',
                isPublic: false,
                routerLink: ['/big-data/sector'],
              },
              {
                label: 'Configuraciones',
                icon: 'pi pi-fw pi-cog',
                resourceName: Resource.MENU_BIG_DATA,
                right: 'canView',
                isPublic: false,
                items: [
                  {
                    label: 'Unidades de Medida',
                    icon: 'pi pi-fw pi-pound',
                    resourceName: Resource.BIG_DATA_UOM_NDC,
                    right: 'canView',
                    isPublic: false,
                    class: 'bg-primary',
                    routerLink: ['/big-data/settings/uom'],
                  },
                ],
              },
            ],
          },
          {
            label: 'Seguimiento',
            icon: 'pi pi-fw pi-directions',
            resourceName: Resource.MENU_BIG_DATA_TRACKING,
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Asignación de Fichas',
                icon: 'pi pi-fw pi-sliders-h',
                resourceName: Resource.BIG_DATA_ASSIGNMENTS,
                right: 'canView',
                isPublic: false,
                routerLink: ['/big-data/tracking/assignments'],
              },
              {
                label: 'Fichas de seguimiento',
                icon: 'pi pi-fw pi-id-card',
                resourceName: Resource.BIG_DATA_TRACKING_CARDS,
                right: 'canView',
                isPublic: false,
                routerLink: ['/big-data/tracking/cards'],
              },
            ],
          },
          {
            label: 'Reportes',
            icon: 'pi pi-file-export',
            resourceName: Resource.BIG_DATA_REPORTS,
            right: 'canView',
            class: 'bg-primary',
            items: [
              {
                label: 'Reportes',
                icon: 'pi pi-file-export',
                resourceName: Resource.BIG_DATA_REPORTS,
                right: 'canView',
                isPublic: true,
                routerLink: ['/big-data/reports'],
              },
              {
                label: 'Dashboards NDC',
                icon: 'pi pi-fw pi-palette',
                resourceName: Resource.MENU_BIG_DATA_DASHBOARDS,
                right: 'canView',
                isPublic: false,
                routerLink: ['/big-data/dashboards'],
              },
            ],
          },
        ],
      },
    ];
  }
}
