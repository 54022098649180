import { Injectable } from '@angular/core';
// import * as dayjs from 'dayjs';
import { Translation } from 'primeng/api';
import { User } from '../../modules/user/interfaces';
import { environment } from '../../../environments/environment';
import {FormGroup} from "@angular/forms";
const dayjs = require('dayjs');

@Injectable({
  providedIn: 'root',
})
export class GenericFunctions {
  constructor() {}
  /**
   * Para que los calendarios primeNG se muestren en español
   */
  public dateEsConfig(): Translation {
    return {
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      today: 'Hoy',
      clear: 'Borrar',
    };
  }

  static truncateText(value: string, maxLength = 20) {
    if (value.length <= maxLength) return value;

    return value.slice(0, maxLength) + '...';
  }

  public parseData(data: Array<any>, keys: Array<any>, f: Array<any>): Array<any> {
    data.forEach((element) => {
      // tslint:disable-next-line: forin
      for (const key in element) {
        keys.forEach((k, index) => {
          if (key === k) {
            if (typeof f[index] === 'string') {
              element[key] = dayjs(element[key]).format('DD/MM/YY');
            } else {
              const temp = f[index].find((e: any) => e.value === element[key]);
              element[key] = temp.label;
            }
          }
        });
      }
    });
    return data;
  }

  public static getUserFullName(user: User, abbr = true) {
    const { primerNombre, segundoNombre, apellidoMaterno, apellidoPaterno } = user || {};

    if (!primerNombre || !apellidoPaterno) return '';
    if (abbr)
      return `${primerNombre} ${this.abbrName(segundoNombre)} ${apellidoPaterno} ${this.abbrName(apellidoMaterno)}`;

    return `${primerNombre} ${segundoNombre || ''} ${apellidoPaterno} ${apellidoMaterno}`;
  }

  public static abbrName(name: string = '') {
    if (!name) return '';

    return `${name.charAt(0).toUpperCase()}.`;
  }

  public getSwalDefaults(): any {
    return {
      text: 'Está seguro que desea guardar',
      type: 'question',
      position: 'center',
      showCancelButton: true,
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      heightAuto: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      cancelButtonClass: 'btn btn-lg btn-default',
      confirmButtonClass: 'btn btn-lg btn-primary ml-2',
      icon: 'question',
    };
  }

  static SortObjectArray(list: any, field: string): any {
    try {
      return list.sort((a: any, b: any) => {
        const tituloA = a[field]
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const tituloB = b[field]
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        return tituloA.localeCompare(tituloB);
      });
    } catch (err) {
      return list;
    }
  }
  static CartographicPaths(): { urlImg: string; urlWms: string; urlWmsDownload: string } {
    return {
      urlImg: environment.host + '/upload/layers/download/',
      urlWms: `${environment.urlGeoserver}/${environment.workspaceGeoserver}/wms`,
      urlWmsDownload: `${environment.urlGeoserver}/${environment.workspaceGeoserver}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${environment.workspaceGeoserver}`,
    };
  }
  static getRandomColor(opacity = 1): string {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    return `rgba(${r},${g},${b}, ${opacity})`;
  }

  static getSimilarColor(hexColor: string, factor = Math.random()) {
    // Convert the hex color to RGB
    const rgb: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    const r = parseInt(rgb[1], 16);
    const g = parseInt(rgb[2], 16);
    const b = parseInt(rgb[3], 16);

    // Adjust the RGB values to create a similar color
    const similarityFactor = factor; // Adjust this value for more or less similarity
    const newR = Math.floor(r * similarityFactor);
    const newG = Math.floor(g * similarityFactor);
    const newB = Math.floor(b * similarityFactor);

    // Convert the adjusted RGB values back to hex
    const newHexColor = `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB
      .toString(16)
      .padStart(2, '0')}`;

    return newHexColor;
  }

  static getRGBAColor(hexColor: string, alpha = 0.5): string {
    const rgb: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    const r = parseInt(rgb[1], 16);
    const g = parseInt(rgb[2], 16);
    const b = parseInt(rgb[3], 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  static isFormChanged(form: FormGroup): boolean {
        let hasChanged = false;
        const keys = Object.keys(form.controls);

        for (const key of keys) {
            const field: any = form.get(key);
            if (field?._pendingDirty) {
                hasChanged = true;
                break;
            }
        }

        return hasChanged;
  }
}
